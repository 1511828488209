import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import TutorialsPage from '../templates/Tutorials';

const host = process.env.GATSBY_HOST;
// let lang = 'en';
// if (typeof window !== 'undefined') {
//   lang = window.localStorage.getItem('language') || 'en';
// }

const tutorials = {
  header: {
    title: 'tutorialsTitle',
    subTitle: 'tutorialsSubTitle',
    image: 'casual-life.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: host
    },
    button2: {
      text: 'tutorialsButton',
      link: '/form/workshop'
    }

  },
  content: {
    title: 'tutorialsContentTitle',
    data: [
      {
        title: 'Google Sheets',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentSheetsText',
        image: 'apps_script.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonGoogleSheetsLink',
        }
      },
      {
        title: 'Google OAuth',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentOAuthText',
        image: 'OAuth.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonOAuthLink',
        }
      },
      {
        title: 'Gmail',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentGmailText',
        image: 'gmail.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonGmailLink',
        }
      },
      {
        title: 'MessageBird',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentMessagebirdText',
        image: 'messegebird.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonMessagebirdLink',
        }
      },
      {
        title: 'Firebase',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentFirebaseText',
        image: 'firebase.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonFirebaseLink',
        }
      },
      {
        title: 'eSputnik',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentEsputnikText',
        image: 'esputnik.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonEsputnikLink',
        }
      },
      {
        title: 'tutorialsContentQueueTitle',
        level: 'tutorialsNewbie',
        duration: 'tutorials1hour',
        text: 'tutorialsContentQueueText',
        image: 'queue.jpg',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonQueueLink',
        }
      },
      {
        title: 'Communications Orchestrator',
        level: 'tutorialsAdvanced',
        duration: 'tutorials5hours',
        text: 'tutorialsContentBotText',
        image: 'bot-platform-2.png',
        button: {
          text: 'tutorialsContentButton',
          link: 'tutorialsContentButtonBotPlatformLink',
        }
      }
    ],
  },
  // theme: {
  //   title: 'Темы',
  //   data: [
  //     {
  //       icon: best,
  //       text: 'Лучшие практики'
  //     },
  //     {
  //       icon: aws,
  //       text: 'Сервисы AWS'
  //     },
  //     {
  //       icon: serves,
  //       text: 'Сервисы email'
  //     },
  //     {
  //       icon: botTutorials,
  //       text: 'Bot Platform'
  //     },
  //     {
  //       icon: google,
  //       text: 'Сервисы Google'
  //     },
  //     {
  //       icon: sms,
  //       text: 'Сервисы SMS'
  //     },
  //   ]
  // }
};


const Tutorials = (props) => (
  <Layout {...props}>
    <TutorialsPage data={tutorials} />
  </Layout>
);

export default withIntl(Tutorials);

